import { useMemo, useState } from "react";

import { ItemType } from "antd/lib/menu/hooks/useItems";
import { clsx } from "clsx";
import { Link, matchPath, useLocation } from "react-router-dom";

import { IconSizes } from "Components/elements/Icon";
import { RVButton } from "Components/elements/RVButton/RVButton";
import SideNavContainer from "Components/shared/SidenavItems/SideNavContainer";
import { TranscriptPaths } from "Pages/transcripts/Pages/paths";
import { useFlags } from "Services/FeatureFlagService";
import { PageSubNavItem } from "Types/Settings";

import styles from "./TranscriptsSideNavBar.module.scss";

enum TranscriptionsPages {
    TRANSCRIPTIONS_LIST = "recordingsAndTranscripts",
    TRANSCRIPTIONS_TRACKERS = "trackers",
    TRANSCRIPTIONS_INSIGHTS = "insights",
    TRANSCRIPTIONS_SCORE_CARDS = "scorecards",
}

const defaultAvailableRoutes: Array<PageSubNavItem> = [
    {
        key: TranscriptionsPages.TRANSCRIPTIONS_LIST,
        title: "Recordings & Transcripts",
        href: TranscriptPaths.recordings,
        tabs: TranscriptPaths.edit,
    },
    {
        key: TranscriptionsPages.TRANSCRIPTIONS_TRACKERS,
        title: "Trackers",
        href: TranscriptPaths.trackers,
    },
];

const insightsPageRoute = {
    key: TranscriptionsPages.TRANSCRIPTIONS_INSIGHTS,
    title: "Insights",
    href: TranscriptPaths.insights,
};

const scoreCardsPageRoute = {
    key: TranscriptionsPages.TRANSCRIPTIONS_SCORE_CARDS,
    title: "Scorecard Templates",
    href: TranscriptPaths.scorecards[0],
    tabs: [TranscriptPaths.scorecards[1]],
};

export default function TranscriptsSideNavBar(): JSX.Element {
    const { conversationsIntelligenceInsights, conversationIntelligenceScoreCards } = useFlags();

    const basePath = "/transcripts";
    const { pathname } = useLocation();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const allAvailableRoutes = useMemo(() => {
        const temp = [...defaultAvailableRoutes];

        conversationIntelligenceScoreCards && temp.push(scoreCardsPageRoute);
        conversationsIntelligenceInsights && temp.push(insightsPageRoute);

        return temp;
    }, [conversationIntelligenceScoreCards, conversationsIntelligenceInsights]);
    const parentStaticRoutes = allAvailableRoutes.map((r) => `${basePath}${r.href}`);

    const selectedItem = allAvailableRoutes.find(
        // match the side menu item by its href
        // or, if it has a list of tabs, find a matching tab
        // this allows the same menu item to be highlighted for multiple URLs
        (item) =>
            matchPath(pathname, { path: basePath + item.href, exact: true }) ||
            item.tabs?.find((tab) => {
                const match = matchPath(pathname, { path: basePath + tab, exact: true });
                return !!match && !parentStaticRoutes.includes(match.url);
            })
    );

    const items = allAvailableRoutes.map((s) => {
        // is disabled only on exact match, if a subroute like transcripts/item is selected then allow redirect
        const isRouteDisabled = selectedItem?.key === s.key && pathname == `${basePath}${s.href}`;
        const isActiveRoute = selectedItem?.key === s.key;

        return {
            disabled: isRouteDisabled,
            className: clsx(styles.menuItem, {
                [styles.selected]: isActiveRoute,
            }),
            key: s.key,
            label: <Link to={`${basePath}${s.href}`}>{s.title}</Link>,
        } as ItemType;
    });

    return (
        <div className={clsx(styles.container)}>
            <SideNavContainer
                sidenavTitle="Conversation Intelligence"
                width={"200px"}
                collapsedWidth={"0px"}
                collapsed={isCollapsed}
                collapsible
                trigger={null}
                data-testid="transcripts-sidenav"
                items={items}
            />

            <RVButton
                className={clsx(styles.expandButton, {
                    [styles.collapsed]: isCollapsed,
                })}
                onClick={() => setIsCollapsed((prev) => !prev)}
                icon={isCollapsed ? "arrow-right" : "arrow-left"}
                iconSize={IconSizes.SMALL}
                data-testid="expand-button"
            ></RVButton>
        </div>
    );
}
