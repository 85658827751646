import { useCallback, useMemo, useState } from "react";

import { useApiWithAbort } from "Services/AbstractApiService";
import { autocompleteOptions } from "Services/Autocomplete/AutocompleteService";

import { RVDebouncedSelect, RVDebouncedSelectProps } from "../RVDebouncedSelect/RVDebouncedSelect";
import { processSelectOptions } from "./Utils/utils";

import type { SelectProps } from "antd";

export type RVSearchableMultiselectFieldProps = {
    value?: any;
    onChange?: (value: any) => void;
    autoClearSearchValue: boolean;
    propertyFieldId: string;
    dataTestid?: string;
    maxTagCount?: number;
    requestOptionsOnly?: boolean;
} & SelectProps;

/**
 * Using our generic autocomplete service, it lets the user search for a value and select multiple options.
 * The user can select exactly what they typed in, even if it's not an option from the service.
 */
export function RVSearchableMultiselectField({
    propertyFieldId,
    autoClearSearchValue,
    requestOptionsOnly = false,
    ...rest
}: RVSearchableMultiselectFieldProps): JSX.Element {
    const [searchTerm, setSearchTerm] = useState("");

    const [dropdownOptions, , loading] = useApiWithAbort<typeof autocompleteOptions>(autocompleteOptions, {
        searchTerm,
        lastEvaluatedKey: null,
        limit: 30,
        fieldId: propertyFieldId,
    });

    const handleOnSelect = useCallback<NonNullable<RVDebouncedSelectProps["onSelect"]>>(() => {
        if (autoClearSearchValue) {
            setSearchTerm("");
        }
    }, [autoClearSearchValue]);

    const selectOptions = useMemo(() => {
        const options = processSelectOptions(dropdownOptions, searchTerm, rest.value, requestOptionsOnly);
        return options;
    }, [dropdownOptions, searchTerm, rest.value, requestOptionsOnly]);

    return (
        <RVDebouncedSelect
            {...rest}
            options={selectOptions}
            autoClearSearchValue={autoClearSearchValue}
            onDebouncedSearch={setSearchTerm}
            onSelect={handleOnSelect}
            loading={loading}
            mode="multiple"
        />
    );
}
