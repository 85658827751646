import { Tooltip, Typography } from "antd";
import { clsx } from "clsx";

import styles from "./Loading.module.scss";

export function Loading({
    text = "Loading your content...",
    small,
    ellipsis = false,
}: {
    text?: string | null;
    small?: boolean;
    ellipsis?: boolean;
}): JSX.Element {
    return (
        <>
            <div className={clsx(styles.wrapper, { [styles.small]: small || !text })}>
                <div className={styles.inner}>
                    <div className={styles.first}></div>
                    <div className={styles.second}></div>
                    <div className={styles.third}></div>
                </div>
                {text && (
                    <div
                        className={clsx(styles.loadingText, {
                            [styles.smallText]: small,
                            [styles.truncateText]: ellipsis,
                        })}
                    >
                        <Tooltip title={text}>
                            <Typography.Text ellipsis={ellipsis}>{text}</Typography.Text>
                        </Tooltip>
                    </div>
                )}
            </div>
        </>
    );
}
